<template>
  <div class="staffList">
    <div class="search_box">
      <el-form
        :inline="true"
        ref="staffSearchForm"
        :model="staffSearchForm"
        label-width="100px"
      >
        <el-form-item class="combinationInput">
          <el-input
            placeholder="請輸入內容"
            v-model="staffSearchForm.staffName"
            class="input-with-select"
          >
            <el-select
              v-model="staffSearchForm.staffId"
              style="width: 130px"
              slot="prepend"
              placeholder="請選擇"
            >
              <el-option label="員工id" value="1"></el-option>
              <el-option label="員工名稱" value="2"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item label="角色：">
          <el-select v-model="staffSearchForm.staffType">
            <el-option label="角色1" value="1"></el-option>
          </el-select>
        </el-form-item>
        <div class="stafflist_ssgn" @click="getStaffList">
          <el-button>搜索</el-button>
          <el-button type="primary">添加</el-button>
        </div>
      </el-form>
    </div>
    <el-table :data="staffList" border class="table" v-loading="tableLoading">
      <el-table-column
        label="員工ID"
        prop="id"
        align="center"
      ></el-table-column>

      <!-- <el-table-column label="商戶號" prop="shh"></el-table-column> -->
      <el-table-column
        label="員工手機號"
        prop="name"
        align="center"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
        label="登錄id"
        prop="people_name"
        align="center"
      ></el-table-column>
      <el-table-column
        label="角色"
        prop="address"
        align="center"
        :show-overflow-tooltip="true"
      ></el-table-column>

      <el-table-column label="操作" width="190">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="info"
            icon="el-icon-edit"
            @click="changeRole(scope.row.id)"
            >變更角色</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分頁 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageIndex"
      :page-sizes="[5, 10, 20]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>
<script>
export default {
  name: "staffSearch",
  data() {
    return {
      staffSearchForm: {
        staffName: "",
        staffId: "",
        staffType: "",
      },
      tableLoading: false,
      pageIndex: 1,
      pageSize: 50,
      total: 0,
      staffList: [],
    };
  },
  methods: {
    getStaffList() {},
    handleSizeChange(val) {},
    handleCurrentChange() {},
    changeRole() {},
  },
};
</script>
<style lang="scss" scoped>
.staffList {
  width: 100%;
  padding: 10px;
  .search_box {
    padding: 10px;
    .stafflist_ssgn {
      display: inline-block;
    }
  }
}
</style>